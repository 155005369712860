<!-- eslint-disable -->
<template>
  <div class="message-count count-box realTime timeMessage">
    <p class="index-title">实时信息</p>
    <div v-if="settingData.monitorShow" id="zb_message_setting" class="el-icon-setting" @mouseup.stop="openSetting">
      <div v-show="settingData.show" id="zb_message_setting_box">
        <p @click.stop="openSetMonitor" class="zb_message_setting_hang">
          <i class="el-icon-edit"></i> 编辑监测
        </p>
        <!-- <p @click.stop="openSetCustom" class="zb_message_setting_hang"><i class="el-icon-set-up"></i> 精准设置</p> -->
      </div>
    </div>
    <!-- 头部  -->
    <div class="message-top">
      <!-- 舆情 -->
      <div class="message-yuqing">
        <span class="message-top-title">舆情调性</span>
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group v-model="checkedTonality" @change="handleCheckedTonalityChange">
          <el-checkbox v-for="itme in tonality" :label="itme" :key="itme.value">{{ itme.label }}</el-checkbox>
        </el-checkbox-group>
        <div class="zhankai-change" @click="zhankaiChange">
          <span class="xia-span">{{ zhankai ? "收起" : "展开" }}</span>
          <span>
            <img class="xia-span-img" src="../../assets/img/views/more.png" alt v-show="zhankai" />
            <img src="../../assets/img/views/xia.png" alt v-show="!zhankai" class="xia-img" />
          </span>
        </div>
      </div>
      <div class="line"></div>
      <!-- 区域 / 媒体-->
      <div :class="['animation', zhankai ? 'shouHeight' : 'defultHeight']">
        <!-- 区域 -->
        <div class="message-region">
          <span class="message-top-title">涉及区域</span>
          <div class="el-checkbox-group">
            <el-radio v-for="itme in areas" v-model="checkedAreas" :key="itme.areaId" :label="itme.areaId">{{
      itme.placeName }}</el-radio>
          </div>
        </div>
        <div class="line"></div>
        <!-- 媒体 -->
        <div class="message-link">
          <span class="message-top-title huanjie">媒体环节</span>
          <div class="el-checkbox-group">
            <el-checkbox v-model="allMedia" @change="mediaCheckedChange">全选</el-checkbox>
            <el-checkbox-group v-model="checkedMedia" @change="mediaChande">
              <el-checkbox v-for="item in media" :label="item" :key="item.id">
                {{ item.mediaClassifyName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="line"></div>

      </div>
      <div class="message-link">
        <InputGroupVue ref="InputGroupVue" />
      </div>
      <div class="line"></div>
      <!-- 标题/正文/作者/来源  事件   时间-->
      <div class="message-time">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <span class="el_font">时间</span>
          <el-date-picker v-model="formInline.value1[0]" type="datetime" size="medium"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始日期">
          </el-date-picker>
          -
          <el-date-picker v-model="formInline.value1[1]" type="datetime" size="medium"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束日期">
          </el-date-picker>
          <el-select class="sortTime" v-model="formInline.sortTime">
            <el-option label="发布时间降序" value="0"></el-option>
            <el-option label="发布时间升序" value="1"></el-option>
            <el-option label="入库时间降序" value="2"></el-option>
            <el-option label="入库时间升序" value="3"></el-option>
          </el-select>
          <el-checkbox style="margin-left: 32px" v-model="warning">已预警</el-checkbox>
          <el-checkbox v-model="repeatData">隐藏重复数据</el-checkbox>
        </el-form>
      </div>
      <div class="line"></div>
      <div class="message-link" style="align-items: center;justify-content: space-between;">
        <div>
          <span class="el_font">自动刷新</span>
          <el-switch v-model="autoRefresh" style="margin-right: 30px;" @change="changeRefreshTime">
          </el-switch>
          <el-radio-group v-model="countdownFrequency" @input="chengCountdownFrequency">
            <el-radio :label="30" :disabled="!autoRefresh">30S</el-radio>
            <el-radio :label="60" :disabled="!autoRefresh">1分钟</el-radio>
            <el-radio :label="180" :disabled="!autoRefresh">3分钟</el-radio>
          </el-radio-group>
        </div>
        <div>
          <el-button type="primary" plain style="width: 60px" size="small" @click="Traceability"
            v-if="$store.state.user.organizationId == 3034">溯源</el-button>
          <el-button type="primary" style="width: 60px" size="small" @click="search">查询</el-button>
          <el-button size="small" class="chongzhi" @click="chongzhi">重置</el-button>
          <el-button class="refresh" style="width: 60px" size="small" @click="search">刷新</el-button>
        </div>
      </div>
      <!-- 检索方案 -->
      <div class="line"></div>
      <div class="serch-row">
        <span class="serch-row-title">检索方案</span>
        <div class="plan-tab">
          <div :class="{
      'plan-tab-li': true,
      'plan-tab-li-active': activeSerchSave == i,
    }" v-for="(val, i) in serchSaveData" :key="i" @click="planYes($event, val, i)">
            {{ JSON.parse(val.schemeContent).serchSaveTitle }}
            <img src="../../assets/img/views/close.png" />
          </div>
        </div>
        <div style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 16%;
          ">
          <el-popover placement="top" width="400" trigger="click">
            <el-radio v-model="isDefault" :label="val.id" v-for="(val, i) in serchSaveData" :key="i"
              @input="isDefaultChange(val)">{{ JSON.parse(val.schemeContent).serchSaveTitle }}</el-radio>
            <div>
              <el-button size="small" style="margin-top: 20px" @click="isDefaultChangeAll">取消启动检索方案</el-button>
            </div>
            <el-button slot="reference" size="small">默认启动检索方案</el-button>
          </el-popover>
          <el-button style="float: right" size="small" @click="serchSave">保存检索方案</el-button>
        </div>
      </div>
    </div>
    <!-- 新消息提示 -->
    <!-- 精简按钮 || 列表按钮  -->
    <div style="
        margin-top: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      ">
      <div id="zb_message_change" v-if="false">
        <div @click="messageTypeClick(1)" :class="messageType == 1 ? 'zb_message_change_select' : ''">
          全部舆情
        </div>
        <div @click="messageTypeClick(2)" :class="messageType == 2 ? 'zb_message_change_select' : ''">
          精准舆情
        </div>
      </div>
      <div v-else></div>
      <transition name="fade">
        <div class="message_newData" v-show="isNewData">
          <i class="el-icon-s-comment" style="color: #2e59ec"></i>
          有新的信息产生，
          <span @click="clickRefresh">点击刷新</span>
        </div>
      </transition>
      <el-radio-group v-model="radioValue" size="medium" @change="changeradio">
        <el-radio-button label="1">
          <img v-if="radioValue == '2'" height="13px" src="../../assets/img/views/jinjianBlack.png" alt="" />
          <img v-else height="13px" src="../../assets/img/views/jinjian.png" alt="" />
          <span v-if="radioValue == '2'" style="color: #666; font-size: 15px; margin-left: 4px">精简</span>
          <span v-else style="color: #fff; font-size: 15px; margin-left: 4px">精简</span>
        </el-radio-button>
        <el-radio-button label="2">
          <img v-if="radioValue == 2" height="13px" src="../../assets/img/views/liebiao.png" alt="" />
          <img v-else height="13px" src="../../assets/img/views/liebiaoBlack.png" alt="" />
          <span v-if="radioValue == '1'" style="color: #666; font-size: 15px; margin-left: 4px">列表</span>
          <span v-else style="color: #fff; font-size: 15px; margin-left: 4px">列表</span>
        </el-radio-button>
      </el-radio-group>
    </div>
    <!-- 列表 -->
    <div class="message-bottom">
      <!-- 每一个模块 -->
      <div v-loading="loading">
        <div v-if="textData.length != 0">
          <!-- 列表 -->
          <template v-if="radioValue == 2">
            <div class="bottom-count" v-for="(item, index) in textData" :key="item.dataId + index">
              <div class="type-count">
                <div :class="[
      'type',
      item.tonalState == 2
        ? 'red'
        : item.tonalState == 1
          ? 'neutral'
          : 'obverse',
    ]">
                  {{
      item.tonalState == 2
        ? "负面"
        : item.tonalState == 0
          ? "正面"
          : "中性"
    }}
                </div>
                <div v-if="item.mediaLink != null" class="type website">
                  {{ item.mediaLink }}
                </div>
              </div>

              <div class="bottom-count-text">
                <div class="bottom-text-box">
                  <div style="display: flex">
                    <span v-show="item.warningFlag && item.warningFlag == 1" class="comment-box-yujing"
                      style="margin-left: 0">已预警</span>
                    <p class="bottom-title" v-html="item.title ? item.title : ''" @click="handelsee(item)"></p>
                    <img class="zb_sentiment_copy" style="width: 24px; height: 24px"
                      src="../../assets/img/views/copy_b.png" @click="myCopy(item, 2)" alt />
                  </div>
                  <div>
                    <span class="original" @click="lookOriginal(item.website)">原文链接</span>
                    <img class="zb_sentiment_copy" src="../../assets/img/views/copy_b.png" @click="myCopy(item, 1)"
                      alt />
                  </div>
                </div>
                <p class="bottom-text" v-html="item.abstracts ? item.abstracts : ''" @click="handelsee(item)"></p>
                <div class="caozuo-box">
                  <div class="publics-caozuo">
                    <span class="publics source">
                      作者ID：
                      <span v-html="item.authorId ? item.authorId : '无'"></span>
                    </span>
                    <span class="publics source">
                      媒体性质:{{
      item.mediaLink +
      (item.mediaAttribute ? "/" + item.mediaAttribute : "") +
      (item.mediaLevel ? "/" + item.mediaLevel : "")
    }}
                    </span>
                    <span class="publics source">
                      来源/作者：
                      <span v-html="item.source ? item.source : '无'"></span>/
                      <span v-html="item.author ? item.author : '无'"></span>
                    </span>
                    <span v-if="item.keyword" class="publics bottom-key">
                      关键字：
                      <span v-for="(ite, indx) in item.keywordList" :class="[
      ite != item.keywordList[item.keywordList.length - 1]
        ? 'styleMargin'
        : '',
    ]" :key="indx">
                        {{ ite }}
                      </span>
                      <el-tooltip v-if="item.keyword && item.keywordArry.length > 3" class="item" effect="dark"
                        placement="top">
                        <div slot="content" v-html="item.keyword"></div>
                        <span class="gengduo-link">(更多)</span>
                      </el-tooltip>
                    </span>
                    <span v-else class="publics bottom-key">
                      关键字：
                      <span>无</span>
                    </span>
                  </div>
                  <div class="publics bottom-time">
                    {{ item.releaseTime ? item.releaseTime : "无" }}
                  </div>
                </div>
              </div>
              <div class="caozuo-copy">
                <el-dropdown trigger="click" placement="bottom-start" @command="handleCommand">
                  <el-button type="primary">
                    一键复制
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{ type: 1, data: item }">复制全部</el-dropdown-item>
                    <el-dropdown-item :command="{ type: 2, data: item }">复制链接与标题</el-dropdown-item>
                    <el-dropdown-item :command="{ type: 3, data: item }">复制作者与链接</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button v-if="$store.state.user.isreport" class="zhuyao" type="primary" plain
                  @click="dataReport(item)">
                  {{ item.isReport == 1 ? "已上报" : "上报数据" }}
                </el-button>
              </div>
            </div>
          </template>
          <!-- 精简 -->
          <template v-else>
            <div class="bottom-count bottom-jingjian-card" v-for="(item, index) in textData" :key="item.dataId + index">
              <div class="bottom-jingjian-card-L">
                <div :class="[
      'type',
      item.tonalState == 2
        ? 'red'
        : item.tonalState == 1
          ? 'neutral'
          : 'obverse',
    ]">
                  {{
      item.tonalState == 2
        ? "负面"
        : item.tonalState == 0
          ? "正面"
          : "中性"
    }}
                </div>
                <p class="bottom-title M-L" v-html="item.title ? item.title : ''" @click="handelsee(item)"></p>
                <span class="original" @click="lookOriginal(item.website)">
                  <img style="position: relative; top: 0px" src="../../assets/img/views/wenzhang.png" alt />
                  <span>查看原文</span>
                </span>
              </div>
              <div class="bottom-jingjian-card-R">
                <div class="publics bottom-time">
                  {{ item.releaseTime ? item.releaseTime : "无" }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="analyse-container" v-else>
          <div class="analyse-container-img">
            <img src="../../assets/img/views/analyse-img.png" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>

      <div class="block">
        <span class="total">
          共{{ tablePage.total }}条, 当前显示第{{
      tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
    }}-{{
        tablePage.page * tablePage.pageSize > tablePage.total
          ? tablePage.total
          : tablePage.page * tablePage.pageSize
      }}条
        </span>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="tablePage.page" :page-sizes="tablePage.pageSizes" :page-size="tablePage.pageSize"
          layout="sizes, prev, pager, next,jumper" :total="tablePage.total"></el-pagination>
      </div>
    </div>
    <el-dialog title="实时信息" :visible.sync="settingData.visible1" width="40%" :close-on-click-modal="false" show-close>
      <div class="zb_message_dialog" v-loading="settingData.loading1">
        <div>
          <span style="margin-right: 20px">预警方式：</span>
          <el-radio v-model="monitorMode" :label="1">自动预警</el-radio>
          <el-radio v-model="monitorMode" :label="2">自定义预警</el-radio>
        </div>
        <div class="zb_message_dialog_box" v-show="monitorMode === 1">
          <div class="zb_message_dialog_title" style="margin-top: 0">
            自动预警设置<span>（设置监测的自动预警方式）</span>
          </div>
          <div class="zb_message_dialog_option" style="margin-left: 30px">
            <el-radio v-model="editMonitor.set" :label="1">不预警</el-radio>
            <span class="zb_message_dialog_span">不进行预警</span>
          </div>
          <div class="zb_message_dialog_option" style="margin-left: 30px">
            <el-radio v-model="editMonitor.set" :label="2">预警首发</el-radio>
            <span class="zb_message_dialog_span">相同舆情只对首条预警，一般用于信息抢报</span>
          </div>
          <div class="zb_message_dialog_option" style="margin-left: 30px">
            <el-radio v-model="editMonitor.set" :label="3">预警全部</el-radio>
            <span class="zb_message_dialog_span">选择调性的所有舆情全部预警，一般用于巡检与监测</span>
          </div>
          <div class="zb_message_dialog_title">
            调性选择<span>（要进行预警的舆情调性）</span>
          </div>
          <div class="zb_message_dialog_option" style="margin-left: 30px">
            <el-checkbox v-model="editMonitor.all" @change="tonalityAll">全部</el-checkbox>
            <el-checkbox @change="editMonitorAll" v-model="editMonitor.tonality[2]">负面</el-checkbox>
            <el-checkbox @change="editMonitorAll" v-model="editMonitor.tonality[1]">中性</el-checkbox>
            <el-checkbox @change="editMonitorAll" v-model="editMonitor.tonality[0]">正面</el-checkbox>
          </div>
        </div>
        <div class="zb_message_dialog_box" v-show="monitorMode === 2">
          <div id="zb_message_dialog_checkbox" class="zb_message_dialog_option" style="margin: 0">
            <div style="display: inline-block; vertical-align: top">
              平台类型：
            </div>
          </div>
          <div>
            <el-checkbox style="vertical-align: top" v-model="editCustom.typeAll" @change="customAll">全选</el-checkbox>
            <el-checkbox-group style="width: 83%" v-model="editCustom.typeSelect" @change="customChange">
              <el-checkbox v-for="value in editCustom.type" :label="value" :key="value">{{ value }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="zb_message_dialog_option">精准舆情：</div>
          <div>
            <el-radio v-model="editCustom.accurate" :label="1">全部数据</el-radio>
            <el-radio v-model="editCustom.accurate" :label="2">精准过滤</el-radio>
          </div>
          <div class="zb_message_dialog_option">舆情调性：</div>
          <div>
            <el-checkbox v-model="editCustom.all" @change="tonalityCustomAll">全部</el-checkbox>
            <el-checkbox @change="editCustomAll" v-model="editCustom.tonality[2]">负面</el-checkbox>
            <el-checkbox @change="editCustomAll" v-model="editCustom.tonality[1]">中性</el-checkbox>
            <el-checkbox @change="editCustomAll" v-model="editCustom.tonality[0]">正面</el-checkbox>
          </div>
          <div class="zb_message_dialog_option">预警条件：</div>
          <div>
            <el-radio-group @change="editCustom.input = ''" v-model="editCustom.condition" size="small">
              <el-radio-button :label="1">预警词语</el-radio-button>
              <el-radio-button :label="2">预警网站</el-radio-button>
              <el-radio-button :label="3">预警账号</el-radio-button>
            </el-radio-group>
          </div>
          <el-input size="small" @keyup.enter.native="customInput" v-model="editCustom.input"
            placeholder="可输入多个关键词逗号分隔，词与词之间为“或”关系，回车确认输入">
          </el-input>
          <div class="zb_message_dialog_option">
            <span style="vertical-align: top">预警词语：</span>
            <div class="zb_message_dialog_tag">
              <el-tag @close="customClose(1, i)" v-for="(tag, i) in editCustom.terms" :key="i" closable>{{ tag
                }}</el-tag>
            </div>
          </div>
          <div class="zb_message_dialog_option">
            <span style="vertical-align: top">预警网站：</span>
            <div class="zb_message_dialog_tag">
              <el-tag @close="customClose(2, i)" v-for="(tag, i) in editCustom.web" :key="i" closable>{{ tag }}</el-tag>
            </div>
          </div>
          <div class="zb_message_dialog_option">
            <span style="vertical-align: top">预警账号：</span>
            <div class="zb_message_dialog_tag">
              <el-tag @close="customClose(3, i)" v-for="(tag, i) in editCustom.id" :key="i" closable>{{ tag }}</el-tag>
            </div>
          </div>
        </div>
      </div>
      <p>
        关启预警设置后，系统将自动根据设置的预警规则对抓取到的信息实时预警推送；
      </p>
      <span slot="footer">
        <el-button size="small" @click="settingData.visible1 = false">取 消</el-button>
        <el-button size="small" @click="saveMonitor" v-loading="monitorLoading" type="primary">保 存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="降噪设置" :visible.sync="settingData.visible2" width="40%" :close-on-click-modal="false" show-close>
      <div class="zb_message_dialog_box2" v-loading="settingData.loading2">
        <div style="margin-top: 0; position: relative; line-height: 28px">
          <el-checkbox v-model="editNoise.distance">
            关键词词距
            <el-tooltip class="zb_message_dialog_tip" content="舆情信息中不同类型关键词之间的间距多少字的值" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-checkbox>
          <el-input-number size="mini" :disabled="!editNoise.distance" v-model="editNoise.distanceNum" :min="1"
            :max="9999" :precision="0"></el-input-number>
        </div>
        <div style="position: relative; line-height: 28px">
          <el-checkbox v-model="editNoise.matching">
            标题匹配关键词
            <el-tooltip class="zb_message_dialog_tip" content="标题中匹配到地域词、主体词、事件词缺一不可" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-checkbox>
        </div>
        <div style="position: relative; line-height: 28px">
          <el-checkbox v-model="editNoise.first">
            地域词首次出现位置
            <el-tooltip class="zb_message_dialog_tip" content="从标题到地域关键词首次出现的间距值" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-checkbox>
          <el-input-number size="mini" :disabled="!editNoise.first" v-model="editNoise.firstNum" :min="1" :max="9999"
            :precision="0"></el-input-number>
        </div>
        <div style="position: relative; line-height: 28px">
          <el-checkbox v-model="editNoise.strong" @change="editNoiseChange">
            地域信息强关联
            <el-tooltip class="zb_message_dialog_tip" content="地域精准匹配规则由3个子规则组成，每个子规则为或的关系" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-checkbox>
          <transition name="tra">
            <div v-show="editNoise.strong" class="zb_message_dialog_strong" style="position: relative">
              <div style="position: relative">
                <el-checkbox v-model="editNoise.big">
                  地域词数量大于等于
                  <el-tooltip class="zb_message_dialog_tip" content="监测地域关键词匹配次数大于等于输入值" placement="top-start">
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </el-checkbox>
                <el-input-number size="mini" :disabled="!editNoise.big" v-model="editNoise.bigNum" :min="1" :max="9999"
                  :precision="0"></el-input-number>
              </div>
              <div style="position: relative">
                <el-checkbox v-model="editNoise.high">
                  全部地域中占比最高
                  <el-tooltip class="zb_message_dialog_tip" content="监测地域关键词比非监测地域词命中占比最高" placement="top-start">
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </el-checkbox>
              </div>
              <div style="position: relative">
                <el-checkbox v-model="editNoise.small">
                  与全部地域的差额值小于
                  <el-tooltip class="zb_message_dialog_tip" content="非监测地域词中匹配次数最大与监测地域关键词的差额小于等于输入值"
                    placement="top-start">
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </el-checkbox>
                <el-input-number size="mini" :disabled="!editNoise.small" v-model="editNoise.smallNum" :min="1"
                  :max="9999" :precision="0"></el-input-number>
              </div>
            </div>
          </transition>
        </div>
        <div style="position: relative; line-height: 28px">
          <el-checkbox v-model="editNoise.wb1">
            忽略微博位置中的关键词
            <el-tooltip class="zb_message_dialog_tip" content="微博位置中的内容不计入监测关键词范围" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-checkbox>
        </div>
        <div style="position: relative; line-height: 28px">
          <el-checkbox v-model="editNoise.wb2">
            忽略微博话题中的关键词
            <el-tooltip class="zb_message_dialog_tip" content="微博话题中的内容不计入监测关键词范围" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-checkbox>
        </div>
        <div style="position: relative; line-height: 28px">
          <el-checkbox v-model="editNoise.wb3">
            忽略微博@中的关键词
            <el-tooltip class="zb_message_dialog_tip" content="微博@中的内容不计入监测关键词范围" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-checkbox>
        </div>
      </div>
      <span slot="footer">
        <el-button size="small" @click="settingData.visible2 = false">取 消</el-button>
        <el-button size="small" @click="saveNoise" v-loading="editNoise.loading" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 保存检索方案 -->
    <el-dialog class="serch-save" title="保存检索方案" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="serchSaveDialog" width="600px">
      <span><span style="color: red">*</span>检索方案名称</span>
      <el-input clearable class="input" size="medium" placeholder="请输入方案名称" v-model="serchSaveTitle" maxlength="10"
        show-word-limit>
      </el-input>
      <div class="serch-save-prompt">
        用户可选择各维度搜索条件进行统一设置，形成对应检索方案下次搜索直接选择对应检索方案即可，无需复杂多类型的去逐一选择检索类型！检索方案名称可设置如：每日必看、一周必看等。
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button plain type="primary" @click="serchSaveNo">取 消</el-button>
        <el-button type="primary" @click="serchSaveYes">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { conditionList } from "../../api/message/list";
import {
  messageList,
  getIncident,
  dataReport,
  saveWarningSetting,
  saveInforAccurateSetting,
  getInforAccurateSetting,
  getWarningSettingFlag,
  getWarningSetting,
} from "../../api/message/list";
import {
  serchSave,
  selectDataSchemeList,
  remove,
  serchEdit,
} from "../../api/index/index.js";
import InputGroupVue from '@/components/InputGroup.vue';
export default {
  components: {
    InputGroupVue
  },
  data() {
    return {
      //自动刷新
      autoRefresh: false,
      //自动更新频率
      countdownFrequency: 60,
      //定时器记录
      setTimeoutTime: 0,
      autoUpdateID: -1,
      newDataCycleID: -1,
      //新数据产生 true 否则 false 
      isNewData: false,
      isDefault: -1,
      activeSerchSave: -1,
      serchSaveData: [],
      serchSaveTitle: "",
      serchSaveDialog: false,
      // 精简1 || 列表2
      radioValue: 2,
      // 列表 加载
      loading: false,
      // 是否展开收起
      zhankai: false,
      //全选
      checkAll: false,
      tonality: [
        {
          label: "负面",
          value: 2,
        },
        {
          label: "中性",
          value: 1,
        },
        {
          label: "正面",
          value: 0,
        },
      ],
      checkedTonality: [],
      //涉及区域
      areas: [],
      checkedAreas: "",
      defultAreas: "",
      //媒体环节
      allMedia: false,
      media: [],
      checkedMedia: [],
      formInline: {
        // 表单标题
        region: "title",
        // 作者模糊搜索
        input1: "",
        // 事件
        value: "",
        // 时间
        value1: [],
        //排序方式
        sortTime: "0",
      },
      // 事件下拉框
      props: {
        lazy: true,
        checkStrictly: true,
        async lazyLoad(node, resolve) {
          const { level } = node;
          let data = {};
          if (node.level == 0) {
            data.parentId = 1;
          }
          if (node.level != 0) {
            data = {
              parentId: node.data.eventId,
            };
          }
          if (node.level < 3) {
            const res = await getIncident(data);
            if (res != undefined) {
              const nodes = res.data.data.map((item) => ({
                value: item,
                label: item.eventName,
                eventId: item.eventId,
                leaf: level >= 10,
              }));

              resolve(nodes);
            }
          } else {
            node.data.leaf = true;
            return resolve([]);
          }
        },
      },
      // 内容
      textData: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0,
      },
      dataList: {},
      //默认开始结束时间
      timerStr: "",
      endTimeStr: "",
      //是否隐藏重复数据
      repeatData: false,
      newDataId: "",
      newDataSearch: null,
      settingData: {
        show: false,
        monitorShow: false,
        visible1: false,
        visible2: false,
        loading1: false,
        loading2: false,
      },
      monitorMode: 1,
      monitorLoading: false,
      //自动预警修改
      editMonitor: {
        set: 1,
        tonality: { 0: false, 1: false, 2: false },
        all: false,
      },
      //自定义预警
      editCustom: {
        type: [
          "网站",
          "纸媒",
          "电视",
          "视频",
          "微信公众号",
          "互动栏目",
          "微博",
          "移动客户端",
        ],
        typeSelect: [],
        typeAll: false,
        accurate: 1,
        tonality: { 0: false, 1: false, 2: false },
        all: false,
        condition: 1,
        input: "",
        terms: [],
        web: [],
        id: [],
      },
      //降噪设置
      editNoise: {
        distance: false,
        distanceNum: 1,
        matching: false,
        first: false,
        firstNum: 1,
        strong: false,
        big: false,
        bigNum: 1,
        high: false,
        small: false,
        smallNum: 1,
        wb1: false,
        wb2: false,
        wb3: false,
        loading: false,
      },
      //数据已预警
      warning: false,
      //1-全部舆情 2-精准舆情
      messageType: 1,
    };
  },
  methods: {
    handleCommand(command) {
      if (command.type == 1) {
        this.copy(command.data);
      } else if (command.type == 2) {
        this.quickCopy(command.data);
      } else if (command.type == 3) {
        this.quickCopy2(command.data);
      }
    },
    async isDefaultChangeAll() {
      let data = [];
      this.isDefault = -1;
      this.serchSaveData.forEach((x) => {
        if (x.isDefault === 1) {
          data.push({
            id: x.id,
            isDefault: 0,
            schemeContent: x.schemeContent,
          });
        }
      });
      await serchEdit(data);
      this.$message({
        type: "success",
        message: "取消成功！",
      });
    },
    async isDefaultChange(value) {
      let data = [];
      this.serchSaveData.forEach((x) => {
        if (x.id !== value.id && x.isDefault === 1) {
          data.push({
            id: x.id,
            isDefault: 0,
            schemeContent: x.schemeContent,
          });
        }
      });
      data.push({
        id: value.id,
        isDefault: 1,
        schemeContent: value.schemeContent,
      });
      await serchEdit(data);
      this.$message({
        type: "success",
        message: "启用成功！",
      });
    },
    planYes(e, val, i) {
      // "DIV" == 选择   "IMG" == 删除
      if (e.target.nodeName == "DIV") {
        this.activeSerchSave = i;
        let data = JSON.parse(val.schemeContent);
        this.checkedTonality = [];
        data.checkedTonality.forEach((x) => {
          this.tonality.forEach((n) => {
            if (x.label === n.label) {
              this.checkedTonality.push(n);
            }
          });
        });
        this.checkAll = data.checkAll;
        this.checkedAreas = data.checkedAreas;
        this.allMedia = data.allMedia;
        this.checkedMedia = [];
        data.checkedMedia.forEach((x) => {
          this.media.forEach((n) => {
            if (x.mediaClassifyName === n.mediaClassifyName) {
              this.checkedMedia.push(n);
            }
          });
        });
        this.formInline.region = data.region;
        this.formInline.input1 = data.input1;
        this.warning = data.warning;
        this.repeatData = data.repeatData;
        this.formInline.value1 = data.value1;
        this.formInline.sortTime = data.sortTime;
        this.tablePage.page = 1;
        this.$refs['InputGroupVue'].setFromData(data)
        this.search();
      } else if (e.target.nodeName == "IMG") {
        this.$confirm("此操作将删除该检索方案, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await remove({ id: val.id });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.serchSaveList();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    async serchSaveList() {
      let res = await selectDataSchemeList({ queryType: 0 });
      this.serchSaveData = res.data.data;
    },
    //保存查询方案
    serchSave() {
      this.serchSaveTitle = "";
      this.serchSaveDialog = true;
    },
    async serchSaveYes() {
      if (this.serchSaveTitle.trim() == "") {
        this.$message.warning("请输入方案名称！");
        return false;
      }
      if (
        this.formInline.value1[0] &&
        this.formInline.value1[1] &&
        this.formInline.value1[0] >= this.formInline.value1[1]
      ) {
        this.$message.warning("请选择正确的时间段");
        return;
      }
      let data = {
        userId: this.$store.state.user.userId,
        queryType: 0,
        isDefault: 0,
        schemeContent: {
          serchSaveTitle: this.serchSaveTitle,
          ...this.$refs.InputGroupVue.getFromData()
        },
      };
      data.schemeContent.checkedTonality = this.checkedTonality;
      data.schemeContent.checkAll = this.checkAll;
      data.schemeContent.checkedAreas = this.checkedAreas;
      data.schemeContent.allMedia = this.allMedia;
      data.schemeContent.checkedMedia = this.checkedMedia;
      data.schemeContent.region = this.formInline.region;
      data.schemeContent.input1 = this.formInline.input1;
      data.schemeContent.warning = this.warning;
      data.schemeContent.repeatData = this.repeatData;
      data.schemeContent.value1 = this.formInline.value1;
      data.schemeContent.sortTime = this.formInline.sortTime;
      data.schemeContent = JSON.stringify(data.schemeContent);

      let res = await serchSave(data);
      if (res.data.code == 200) {
        this.$message({
          message: "保存成功！",
          type: "success",
        });
        this.serchSaveNo();
        this.serchSaveList();
      }
    },
    serchSaveNo() {
      this.serchSaveTitle = "";
      this.serchSaveDialog = false;
    },
    // 用Promise 封装setTimeout
    setTimeout: (time) => new Promise((res) => setTimeout(res, time)),
    // 精简 || 列表
    async changeradio() {
      this.loading = true;
      await this.setTimeout(300);
      this.loading = false;
    },
    // 正/中/负  全选
    handleCheckAllChange(val) {
      this.checkedTonality = val ? this.tonality : [];
    },
    handleCheckedTonalityChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.tonality.length;
    },
    //涉及区域
    // handleCheckAllChanges() {
    // console.log(this.checkedAreas);
    // if (val.areaId === 0) {
    //   if (val.checked) {
    //     this.checkedAreas = [];
    //     this.areas.forEach(x => {
    //       x.checked = true;
    //       if (x.areaId !== 0) {
    //         this.checkedAreas.push(x);
    //       }
    //     });
    //   } else {
    //     this.checkedAreas = [];
    //     this.areas.forEach(x => {
    //       x.checked = false;
    //     });
    //   }
    // } else {
    //   this.checkedAreas = [];
    //   this.areas.forEach(x => {
    //     if (x.checked && x.areaId !== 0) {
    //       this.checkedAreas.push(x);
    //     }
    //   });
    //   if (this.checkedAreas.length == this.areas.length - 1) {
    //     this.areas[0].checked = true;
    //   } else {
    //     this.areas[0].checked = false;
    //   }
    // }
    // },
    // 媒体环节全选
    mediaCheckedChange(val) {
      this.checkedMedia = val ? this.media : [];
    },
    mediaChande(value) {
      let checkedCount = value.length;
      this.allMedia = checkedCount === this.media.length;
    },
    //去详情
    handelsee(item) {
      let routeUrl = this.$router.resolve({
        path: "/message/index/original",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.dataId,
              organId: item.organId,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 复制
    copy(item) {
      this.$publicFun.customCopy2(item);
    },
    //复制链接与标题
    quickCopy(msg) {
      let str = "";
      str = "标题：" + msg.title + "\n" + "链接：" + msg.website;
      this.$publicFun.copyFun(str);
    },
    //复制链接与标题
    quickCopy2(msg) {
      let str = "";
      str = msg.author + msg.website;
      this.$publicFun.copyFun(str);
    },
    myCopy(msg, type) {
      let str = "";
      switch (type) {
        case 1:
          str = "链接：" + msg.website;
          break;
        case 2:
          str = "标题：" + msg.title;
          break;
      }
      this.$publicFun.copyFun(str);
    },
    //展开关闭
    zhankaiChange() {
      this.zhankai = !this.zhankai;
    },
    // 时间框获得焦点
    focusDate() {
      this.formInline.value1 = [];
      this.formInline.value1[0] =
        this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + " " + "00:00:00";
      this.formInline.value1[1] = this.$calcu.timeCycle(
        new Date(),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    search() {
      let data = {};
      //舆情调性
      if (
        this.formInline.value1[0] &&
        this.formInline.value1[1] &&
        this.formInline.value1[0] >= this.formInline.value1[1]
      ) {
        this.$message.warning("请选择正确的时间段");
        return;
      }
      if (!this.formInline.value1[0] || !this.formInline.value1[1]) {
        this.formInline.value1 = [];
      }
      if (this.checkedTonality.length > 0 && !this.checkAll) {
        let checkedTonality = [];
        this.checkedTonality.forEach((x) => {
          checkedTonality.push(x.value);
        });
        data.tonalState = checkedTonality.join(",");
      }
      // 地区
      if (this.checkedAreas) {
        data.areaInvolved = this.checkedAreas;
      }
      // 媒体环节
      if (this.checkedMedia.length > 0 && !this.allMedia) {
        let medLinkList = [];
        this.checkedMedia.forEach((item) => {
          medLinkList.push(item.mediaClassifyName);
        });
        data.mediaLink = medLinkList.join(",");
      }
      //标题 正文  作者 region
      // if (this.formInline.input1 != "") {
      //   data[this.formInline.region] = this.formInline.input1;
      // }
      data = { ...data, ...this.$refs.InputGroupVue.getFromData() }
      if (this.repeatData) {
        data.isRepeat = 0;
      }
      //时
      if (
        this.formInline.value1 != false &&
        this.formInline.value1[0] &&
        this.formInline.value1[1]
      ) {
        data.startTimeStr =
          this.formInline.value1[0].replace(" ", "T") + "+08:00";
        data.endTimeStr =
          this.formInline.value1[1].replace(" ", "T") + "+08:00";
      }
      // 事件 formInline.value
      if (this.formInline.value.length != 0) {
        data.eventClass =
          this.formInline.value[this.formInline.value.length - 1].eventName;
      }
      //排序
      // <el-option label="发布时间降序" value="0"></el-option>
      // <el-option label="发布时间升序" value="1"></el-option>
      // <el-option label="入库时间降序" value="2"></el-option>
      // <el-option label="入库时间升序" value="3"></el-option>
      if (this.formInline.sortTime == "0") {
        data.sortOrder = 0;
        data.sortTime = "releaseTime";
      } else if (this.formInline.sortTime == "1") {
        data.sortOrder = 1;
        data.sortTime = "releaseTime";
      } else if (this.formInline.sortTime == "2") {
        data.sortOrder = 0;
        data.sortTime = "createTime";
      } else if (this.formInline.sortTime == "3") {
        data.sortOrder = 1;
        data.sortTime = "createTime";
      }
      if (this.warning) data.warningFlag = 1;
      this.tablePage.page = 1;
      this.messageList(data, 1);
    },
    //溯源
    async Traceability() {
      if (
        this.checkedTonality.length == 0 &&
        this.warning == false &&
        this.repeatData == false &&
        this.checkedAreas == "" &&
        this.checkedMedia.length == 0 &&
        this.formInline.input1 == "" &&
        this.formInline.value1.length == 0
      ) {
        this.$message.warning("请选最少选择一项筛选条件！");
      } else {
        this.loading = true;
        this.timerStr = this.$calcu.calcuTime(3) + "T00:00:00+08:00";
        this.endTimeStr =
          this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + "T23:59:59+08:00";
        let data = {
          organId: this.$store.state.user.organizationId,
          pageSize: 1,
          pageNum: 1,
          dataTraceability: 1,
        };
        data.reportOrganId = this.$store.state.user.organizationId;
        //舆情调性
        if (
          this.formInline.value1[0] &&
          this.formInline.value1[1] &&
          this.formInline.value1[0] >= this.formInline.value1[1]
        ) {
          this.$message.warning("请选择正确的时间段");
          return;
        }
        if (!this.formInline.value1[0] || !this.formInline.value1[1]) {
          this.formInline.value1 = [];
        }
        if (this.checkedTonality.length > 0 && !this.checkAll) {
          let checkedTonality = [];
          this.checkedTonality.forEach((x) => {
            checkedTonality.push(x.value);
          });
          data.tonalState = checkedTonality.join(",");
        }
        // 地区
        if (this.checkedAreas) {
          data.areaInvolved = this.checkedAreas;
        }
        // 媒体环节
        if (this.checkedMedia.length > 0 && !this.allMedia) {
          let medLinkList = [];
          this.checkedMedia.forEach((item) => {
            medLinkList.push(item.mediaClassifyName);
          });
          data.mediaLink = medLinkList.join(",");
        }
        //标题 正文  作者 region
        if (this.formInline.input1 != "") {
          data[this.formInline.region] = this.formInline.input1;
        }
        if (this.repeatData) {
          data.isRepeat = 0;
        }
        //时
        if (
          this.formInline.value1 != false &&
          this.formInline.value1[0] &&
          this.formInline.value1[1]
        ) {
          data.startTimeStr =
            this.formInline.value1[0].replace(" ", "T") + "+08:00";
          data.endTimeStr =
            this.formInline.value1[1].replace(" ", "T") + "+08:00";
        }
        // 事件 formInline.value
        if (this.formInline.value.length != 0) {
          data.eventClass =
            this.formInline.value[this.formInline.value.length - 1].eventName;
        }
        if (this.warning) {
          data.warningFlag = 1;
        }
        data.reportOrganId = this.$store.state.user.organizationId;
        if (this.messageType == 2) data.accurateFlag = 1;
        const res = await messageList(data);
        if (res.data.code == 200 && res.data.data && res.data.data.result) {
          this.textData = res.data.data.result;
          this.tablePage.total = 1;
          this.textData.forEach((item) => {
            item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
            if (item.eventClass) {
              item.eventList = item.eventClass.split(" ");
              let reg = new RegExp(" ", "g"); //g代表全部
              item.eventClass = item.eventClass.replace(reg, "<br/>");
            } else {
              item.eventList = [];
            }
            if (item.keyword) {
              item.keywordList = item.keyword.split(" ");
              item.keywordArry = item.keyword.split(" ");
              if (item.keywordList.length > 3) {
                item.keywordList = item.keywordList.slice(0, 3);
              }
              let reg = new RegExp(" ", "g"); //g代表全部
              item.keyword = item.keyword.replace(reg, "<br/>");
            } else {
              item.keywordList = [];
              item.keywordArry = [];
            }
          });
        } else {
          this.textData = [];
        }
        if (res.data.code == 200 && data.pageNum == 1) {
          // && 的意思如果有数据再去用它的第一位 免得报错
          this.newDataId = res.data.data.result[0]
            ? res.data.data.result[0].inforId
            : null;
          this.newDataSearch = JSON.parse(JSON.stringify(data));
          this.newDataSearch.pageSize = 10;
          this.newDataSearch.pageNum = 1;
        }
        this.loading = false;
      }
    },
    // 获取列表
    async messageList(obj) {
      this.loading = true;
      this.setTimeoutTime = 0;
      this.timerStr = this.$calcu.calcuTime(3) + "T00:00:00+08:00";
      this.endTimeStr =
        this.$calcu.timeCycle(new Date(), "yyyy-MM-dd") + "T23:59:59+08:00";
      let data = {
        organId: this.$store.state.user.organizationId,
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.page,
        startTimeStr: this.timerStr,
        endTimeStr: this.endTimeStr,
      };
      if (obj) {
        this.dataList = obj;
        this.dataList.organId = this.$store.state.user.organizationId;
        this.dataList.pageSize = this.tablePage.pageSize;
        this.dataList.pageNum = this.tablePage.page;
        if (this.dataList.startTimeStr) {
          data.startTimeStr = this.dataList.startTimeStr;
        } else {
          this.dataList.startTimeStr = data.startTimeStr;
        }
        if (this.dataList.endTimeStr) {
          data.endTimeStr = this.dataList.endTimeStr;
        } else {
          this.dataList.endTimeStr = data.endTimeStr;
        }
        data = this.dataList;
      } else if (Object.values(this.dataList).length > 0) {
        data = this.dataList;
        data.pageSize = this.tablePage.pageSize;
        data.pageNum = this.tablePage.page;
      }
      data.reportOrganId = this.$store.state.user.organizationId;
      if (this.messageType == 2) data.accurateFlag = 1;
      const res = await messageList(data);
      if (res.data.code == 200 && res.data.data && res.data.data.result) {
        this.textData = res.data.data.result;
        this.tablePage.total = res.data.data.total;
        this.textData.forEach((item) => {
          item.releaseTime = item.releaseTime.slice(0, 19).replace("T", " ");
          if (item.eventClass) {
            item.eventList = item.eventClass.split(" ");
            let reg = new RegExp(" ", "g"); //g代表全部
            item.eventClass = item.eventClass.replace(reg, "<br/>");
          } else {
            item.eventList = [];
          }
          if (item.keyword) {
            item.keywordList = item.keyword.split(" ");
            item.keywordArry = item.keyword.split(" ");
            if (item.keywordList.length > 3) {
              item.keywordList = item.keywordList.slice(0, 3);
            }
            let reg = new RegExp(" ", "g"); //g代表全部
            item.keyword = item.keyword.replace(reg, "<br/>");
          } else {
            item.keywordList = [];
            item.keywordArry = [];
          }
        });
      } else {
        this.textData = [];
      }
      if (res.data.code == 200 && data.pageNum == 1) {
        // && 的意思如果有数据再去用它的第一位 免得报错
        this.newDataId = res.data.data.result[0]
          ? res.data.data.result[0].inforId
          : null;
        this.newDataSearch = JSON.parse(JSON.stringify(data));
        this.newDataSearch.pageSize = 10;
        this.newDataSearch.pageNum = 1;
      }
      this.loading = false;
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.messageList();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.messageList();
      window.scrollTo(0, 0);
    },
    // 重置
    chongzhi() {
      this.tablePage.page = 1;
      this.activeSerchSave = -1;
      this.dataList = {};
      this.checkAll = false;
      this.checkedTonality = [];
      this.checkedAreas = "";
      this.formInline = {
        // 表单标题
        region: "title",
        // 作者模糊搜索
        input1: "",
        // 事件
        value: "",
        // 时间
        value1: [],
        //排序方式
        sortTime: "0",
      };
      // 标题 等等
      this.$refs['InputGroupVue'].clearFromData()
      this.checkedMedia = [];
      this.allMedia = false;
      this.areas.forEach((x) => {
        x.checked = false;
      });
      this.warning = false;
      this.repeatData = false;
      this.messageList();
    },
    //查看原文
    lookOriginal(web) {
      window.open(web);
    },
    //数据上报
    async dataReport(item) {
      let data = {
        infoType: 0,
        inforMsgId: item.dataId,
        organizationId: this.$store.state.user.organizationId,
        reportOrganId: item.organId,
      };
      const res = await dataReport(data);
      if (res != undefined) {
        if (res.data.data.code !== 200) {
          this.$message({
            message: res.data.data.msg,
            type: "warning",
          });
        } else {
          this.$message({
            message: res.data.data.msg,
            type: "success",
          });
          setTimeout(() => {
            this.messageList();
          }, 1000);
        }
      }
    },
    //新数据提示
    newDataCycle() {
      if (!this.autoRefresh && this.newDataSearch) {
        messageList(this.newDataSearch).then((res) => {
          if (
            res.data.code == 200 &&
            res.data.data.result.length > 0 &&
            res.data.data.result[0].inforId != this.newDataId
          ) {
            this.isNewData = true;
          }
        });
      }
      this.newDataCycleID = setTimeout(() => {
        this.newDataCycle();
      }, 10000);
    },
    //手动刷新
    clickRefresh() {
      this.tablePage.page = 1;
      this.isNewData = false;
      this.messageList();
    },
    //开启关闭自动刷新
    changeRefreshTime() {
      this.setTimeoutTime = 0;
    },
    //自动更新频率
    chengCountdownFrequency(value) {
      if (value !== this.countdownFrequency) {
        //清空定时器记录
        this.setTimeoutTime = 0;
      }
    },
    //自动更新
    autoUpdate() {
      if (this.setTimeoutTime > this.countdownFrequency && this.autoRefresh) {
        this.tablePage.page = 1;
        this.messageList();
      }
      this.autoUpdateID = setTimeout(() => {
        this.setTimeoutTime++;
        this.autoUpdate();
      }, 1000);
    },
    openSetting() {
      if (this.settingData.show) {
        this.settingData.show = false;
        window.removeEventListener("mouseup", close);
      } else {
        this.settingData.show = true;
        window.addEventListener("mouseup", close);
      }
      let that = this;
      function close() {
        that.settingData.show = false;
        window.removeEventListener("mouseup", close);
        that.$forceUpdate();
      }
    },
    //自动预警调性全选
    tonalityAll(data) {
      if (data) {
        for (let i = 0; i < 3; i++) {
          this.editMonitor.tonality[i] = true;
        }
      } else {
        for (let i = 0; i < 3; i++) {
          this.editMonitor.tonality[i] = false;
        }
      }
    },
    //自动预警调性变化全选判断
    editMonitorAll() {
      let judge = false;
      for (let i in this.editMonitor.tonality) {
        if (this.editMonitor.tonality[i] === false) {
          judge = true;
        }
      }
      if (judge) {
        this.editMonitor.all = false;
      } else {
        this.editMonitor.all = true;
      }
    },
    //自定义预警平台类型变化
    customChange(value) {
      let checkedCount = value.length;
      this.editCustom.typeAll = checkedCount === this.editCustom.type.length;
    },
    //自定义预警平台类型全选
    customAll(val) {
      this.editCustom.typeSelect = val ? this.editCustom.type : [];
    },
    //自动预警调性全选
    tonalityCustomAll(data) {
      if (data) {
        for (let i = 0; i < 3; i++) {
          this.editCustom.tonality[i] = true;
        }
      } else {
        for (let i = 0; i < 3; i++) {
          this.editCustom.tonality[i] = false;
        }
      }
    },
    //自动预警调性变化全选判断
    editCustomAll() {
      let judge = false;
      for (let i in this.editCustom.tonality) {
        if (this.editCustom.tonality[i] === false) {
          judge = true;
        }
      }
      if (judge) {
        this.editCustom.all = false;
      } else {
        this.editCustom.all = true;
      }
    },
    //自动预警预警条件输入
    customInput() {
      if (this.editCustom.input.trim()) {
        let arr = this.editCustom.input.split(",").map((x) => x.trim());
        switch (this.editCustom.condition) {
          case 1:
            arr.forEach((x) => {
              this.editCustom.terms.push(x);
            });
            break;
          case 2:
            arr.forEach((x) => {
              this.editCustom.web.push(x);
            });
            break;
          case 3:
            arr.forEach((x) => {
              this.editCustom.id.push(x);
            });
            break;
        }
        this.editCustom.input = "";
      }
    },
    //自定义预警关闭标签
    customClose(type, i) {
      switch (type) {
        case 1:
          this.editCustom.terms.splice(i, 1);
          break;
        case 2:
          this.editCustom.web.splice(i, 1);
          break;
        case 3:
          this.editCustom.id.splice(i, 1);
          break;
      }
    },
    //打开编辑监测页面
    openSetMonitor() {
      this.settingData.visible1 = true;
      this.settingData.loading1 = true;
      getWarningSetting().then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.settingData.loading1 = false;
          this.monitorLoading = false;
          if (data.initFlag === 1) {
            this.monitorMode = 1;
            this.editMonitor = {
              set: 1,
              tonality: { 0: false, 1: false, 2: false },
              all: false,
            };
            this.editCustom = {
              type: [
                "网站",
                "纸媒",
                "电视",
                "视频",
                "微信公众号",
                "互动栏目",
                "微博",
                "移动客户端",
              ],
              typeSelect: [],
              typeAll: false,
              accurate: 1,
              tonality: { 0: false, 1: false, 2: false },
              all: false,
              condition: 1,
              input: "",
              terms: [],
              web: [],
              id: [],
            };
          } else {
            if (data.flag === 0) {
              this.monitorMode = 1;
              this.editMonitor = {
                set: 1,
                tonality: { 0: false, 1: false, 2: false },
                all: false,
              };
              this.editCustom = {
                type: [
                  "网站",
                  "纸媒",
                  "电视",
                  "视频",
                  "微信公众号",
                  "互动栏目",
                  "微博",
                  "移动客户端",
                ],
                typeSelect: [],
                typeAll: false,
                accurate: 1,
                tonality: { 0: false, 1: false, 2: false },
                all: false,
                condition: 1,
                input: "",
                terms: [],
                web: [],
                id: [],
              };
              if (data.tonalStateSetting.indexOf(0) != -1)
                this.editMonitor.tonality[0] = true;
              if (data.tonalStateSetting.indexOf(1) != -1)
                this.editMonitor.tonality[1] = true;
              if (data.tonalStateSetting.indexOf(2) != -1)
                this.editMonitor.tonality[2] = true;
              if (
                this.editMonitor.tonality[2] &&
                this.editMonitor.tonality[1] &&
                this.editMonitor.tonality[0]
              )
                this.editMonitor.all = true;
              this.editMonitor.set = data.autoWarningSetting + 1;
            } else {
              this.monitorMode = 2;
              this.editMonitor = {
                set: 1,
                tonality: { 0: false, 1: false, 2: false },
                all: false,
              };
              this.editCustom = {
                type: [
                  "网站",
                  "纸媒",
                  "电视",
                  "视频",
                  "微信公众号",
                  "互动栏目",
                  "微博",
                  "移动客户端",
                ],
                typeSelect: [],
                typeAll: false,
                accurate: 1,
                tonality: { 0: false, 1: false, 2: false },
                all: false,
                condition: 1,
                input: "",
                terms: [],
                web: [],
                id: [],
              };
              this.editCustom.typeSelect = data.platformSetting;
              this.editCustom.type.length == data.platformSetting.length
                ? (this.editCustom.typeAll = true)
                : (this.editCustom.typeAll = false);
              this.editCustom.accurate = data.whetherAccurate + 1;
              if (data.tonalStateSetting.indexOf(0) != -1)
                this.editCustom.tonality[0] = true;
              if (data.tonalStateSetting.indexOf(1) != -1)
                this.editCustom.tonality[1] = true;
              if (data.tonalStateSetting.indexOf(2) != -1)
                this.editCustom.tonality[2] = true;
              if (
                this.editCustom.tonality[2] &&
                this.editCustom.tonality[1] &&
                this.editCustom.tonality[0]
              )
                this.editCustom.all = true;
              this.editCustom.terms = data.warningCondition.warningTerms;
              this.editCustom.web = data.warningCondition.warningWebsite;
              this.editCustom.id = data.warningCondition.warningAccount;
            }
          }
        }
      });
    },
    //打开精准设置页面
    openSetCustom() {
      this.settingData.visible2 = true;
      this.settingData.loading2 = true;
      getInforAccurateSetting().then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.settingData.loading2 = false;
          if (data.initFlag === 1) {
            this.editNoise = {
              distance: false,
              distanceNum: 1,
              matching: false,
              first: false,
              firstNum: 1,
              strong: false,
              big: false,
              bigNum: 1,
              high: false,
              small: false,
              smallNum: 1,
              wb1: false,
              wb2: false,
              wb3: false,
              loading: false,
            };
          } else {
            this.editNoise = {
              distance: data.keyword.open === 1,
              distanceNum: data.keyword.open === 1 ? data.keyword.num : 1,
              matching: data.matchTitle === 1,
              first: data.firstRegion.open === 1,
              firstNum: data.firstRegion.open === 1 ? data.firstRegion.num : 1,
              strong: false,
              big: false,
              bigNum: 1,
              high: false,
              small: false,
              smallNum: 1,
              wb1: data.ignorePosition === 1,
              wb2: data.ignoreTopic === 1,
              wb3: data.ignoreKeyword === 1,
              loading: false,
            };
            if (
              data.regionMsg.matchRegionG.open === 1 ||
              data.regionMsg.allRegion === 1 ||
              data.regionMsg.matchRegionL.open === 1
            ) {
              this.editNoise.strong = true;
              this.editNoise.big = data.regionMsg.matchRegionG.open === 1;
              this.editNoise.bigNum =
                data.regionMsg.matchRegionG.open === 1
                  ? data.regionMsg.matchRegionG.num
                  : 0;
              this.editNoise.high = data.regionMsg.allRegion === 1;
              this.editNoise.small = data.regionMsg.matchRegionL.open === 1;
              this.editNoise.smallNum =
                data.regionMsg.matchRegionL.open === 1
                  ? data.regionMsg.matchRegionL.num
                  : 0;
            }
          }
        }
      });
    },
    //保存编辑监测页面
    saveMonitor() {
      let data = {};
      if (this.monitorMode === 1) {
        if (this.editMonitor.set != 1) {
          let judge = true;
          for (let i = 0; i < 3; i++) {
            if (this.editMonitor.tonality[i]) judge = false;
          }
          if (judge) {
            this.$message({ type: "warning", message: "请选择调性" });
            return;
          }
        }
        this.monitorLoading = true;
        data.flag = 0;
        data.autoWarningSetting = this.editMonitor.set - 1;
        data.tonalStateSetting = [];
        for (let i in this.editMonitor.tonality) {
          if (this.editMonitor.tonality[i]) data.tonalStateSetting.push(i * 1);
        }
      } else if (this.monitorMode === 2) {
        if (this.editCustom.typeSelect.length == 0) {
          this.$message({ type: "warning", message: "请选择平台类型" });
          return;
        }
        let judge = true;
        for (let i = 0; i < 3; i++) {
          if (this.editCustom.tonality[i]) judge = false;
        }
        if (judge) {
          this.$message({ type: "warning", message: "请选择调性" });
          return;
        }
        this.monitorLoading = true;
        data.flag = 1;
        data.platformSetting = this.editCustom.typeSelect;
        data.whetherAccurate = this.editCustom.accurate - 1;
        data.tonalStateSetting = [];
        for (let i in this.editMonitor.tonality) {
          if (this.editCustom.tonality[i]) data.tonalStateSetting.push(i * 1);
        }
        data.warningCondition = {
          warningAccount: this.editCustom.id,
          warningWebsite: this.editCustom.web,
          warningTerms: this.editCustom.terms,
        };
      }
      saveWarningSetting(data)
        .then((res) => {
          this.monitorLoading = false;
          if (res.data.code == 200) {
            this.$message({ type: "success", message: "保存成功！" });
            this.settingData.visible1 = false;
          } else {
            this.$message({ type: "error", message: "保存失败！" });
          }
        })
        .catch(() => {
          this.monitorLoading = false;
          this.$message({ type: "error", message: "保存失败！" });
        });
    },
    saveNoise() {
      this.editNoise.loading = true;
      let data = {};
      data.keyword = {
        open: 0,
        num: 0,
      };
      if (this.editNoise.distance) {
        data.keyword = {
          open: 1,
          num: this.editNoise.distanceNum,
        };
      }
      data.matchTitle = 0;
      if (this.editNoise.matching) {
        data.matchTitle = 1;
      }
      data.firstRegion = {
        open: 0,
        num: 0,
      };
      if (this.editNoise.first) {
        data.firstRegion = {
          open: 1,
          num: this.editNoise.firstNum,
        };
      }
      data.regionMsg = {
        matchRegionG: {
          open: 0,
          num: 0,
        },
        allRegion: 0,
        matchRegionL: {
          open: 0,
          num: 0,
        },
      };
      if (this.editNoise.strong) {
        if (this.editNoise.big) {
          data.regionMsg.matchRegionG = {
            open: 1,
            num: this.editNoise.bigNum,
          };
        }
        if (this.editNoise.high) {
          data.regionMsg.allRegion = 1;
        }
        if (this.editNoise.small) {
          data.regionMsg.matchRegionL = {
            open: 1,
            num: this.editNoise.smallNum,
          };
        }
      }
      data.ignorePosition = 0;
      if (this.editNoise.wb1) {
        data.ignorePosition = 1;
      }
      data.ignoreTopic = 0;
      if (this.editNoise.wb2) {
        data.ignoreTopic = 1;
      }
      data.ignoreKeyword = 0;
      if (this.editNoise.wb3) {
        data.ignoreKeyword = 1;
      }
      saveInforAccurateSetting(data)
        .then((res) => {
          if (res.data.code == 200) {
            this.editNoise.loading = false;
            this.$message({ type: "success", message: "保存成功！" });
            this.settingData.visible1 = false;
            this.settingData.visible2 = false;
          } else {
            this.$message({ type: "error", message: "保存失败！" });
          }
        })
        .catch(() => {
          this.editNoise.loading = false;
          this.$message({ type: "error", message: "保存失败！" });
        });
    },
    //获取是否打开编辑监测功能
    getMonitorJur() {
      getWarningSettingFlag().then((res) => {
        if (res.data.code == 200 && res.data.data === 1) {
          this.settingData.monitorShow = true;
        }
      });
    },
    //切换信息类型
    messageTypeClick(type) {
      if (this.messageType === type) {
        return;
      } else {
        this.messageType = type;
        this.search();
      }
    },
    editNoiseChange(val) {
      if (val === false) {
        this.editNoise.big = false;
        this.editNoise.high = false;
        this.editNoise.small = false;
      }
    },
  },
  mounted() {
    this.newDataCycle();
    this.autoUpdate();
    if(this.$route.query.toType==1){//当天数据
      this.formInline.value1=[this.$calcu.timeCycle(new Date(), "yyyy-MM-dd")+ " " + "00:00:00",this.$calcu.timeCycle(new Date(), "yyyy-MM-dd")+ " " + "23:59:59"]
      this.search()
    }
  },
  created() {
    this.$http
      .all([
        selectDataSchemeList({ queryType: 0 }),
        conditionList({
          areaId: this.$store.state.user.areaId,
          organizationId: this.$store.state.user.organizationId,
        }),
      ])
      .then((res) => {
        let ifPlan = false;
        res[1].data.data.regional.forEach((item, index) => {
          if (item.areaId == 0) {
            res[1].data.data.regional.splice(index, 1);
          }
        });
        this.areas = res[1].data.data.regional;
        if (this.$route.query.info == "warning") {
          this.messageList().then(() => {
            this.warning = true;
            this.search();
            this.$router.push({ query: {} });
          });
        } else if (this.$route.query.info == "today") {
          this.formInline.value1[0] = this.$calcu.calcuTime(0) + " 00:00:00";
          this.formInline.value1[1] = this.$calcu.calcuTime(0) + " 23:59:59";
          this.search();
          this.$router.push({ query: {} });
        } else {
          this.media = res[1].data.data.mediaLink.splice(1);
          this.serchSaveData = res[0].data.data;
          this.serchSaveData.forEach((x, i) => {
            if (x.isDefault == 1) {
              ifPlan = true;
              this.isDefault = x.id;
              this.planYes(
                { target: { nodeName: "DIV", className: "plan-tab-li" } },
                x,
                i
              );
            }
          });
          if (!ifPlan) {
            this.search();
          }
        }
      });
    this.messageList();
    this.getMonitorJur();
  },
  destroyed() {
    clearTimeout(this.autoUpdateID);
    clearTimeout(this.newDataCycleID);
  },
};
</script>

<style scoped>
.sortTime {
  margin-left: 32px;
  width: 150px;
}

.M-L {
  margin-left: 10px;
}

::v-deep .el-radio-button--medium .el-radio-button__inner {
  padding: 6px 8px;
}

.bottom-jingjian-card {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
}

.bottom-jingjian-card-L {
  display: flex;
}

.bottom-jingjian-card-R {
  display: flex;
  font-size: 14px;
}

.count-box {
  padding: 24px 24px 24px 24px;
}

.message-count .message-top {
  padding: 0 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 24px;
}

.message-count .message-yuqing,
.message-count .message-region,
.message-count .message-link {
  min-height: 58px;
  display: flex;
  line-height: 52px;
  width: 100%;
}

.message-count .message-region .el-checkbox-group {
  display: flex;
  min-height: 58px;
  line-height: 52px;
  align-items: center;
}

.message-count .message-time {
  /* line-height: 68px; */
  height: 68px;
  width: 100%;
}

.message-count .line {
  width: 100%;
  height: 1px;
  background: #e4e6ec;
}

.message-count .message-top-title {
  width: 60px;
  padding-top: 1px;
  margin-top: 16px;
  margin-right: 40px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #999999;
}

.message-count .huanjie {
  margin-right: 40px;
}

.message-count .el-checkbox-group {
  height: 100%;
  display: inline-block;
  width: 87%;
}

.message-count .el-checkbox {
  margin-right: 48px;
}

.message-count .el-checkbox .el-checkbox__input {
  width: 14px;
  height: 14px;
}

.message-count .demo-form-inline {
  height: 100%;
  padding-top: 16px;
}

.message-count .select-item,
.el-select .select {
  margin: 0;
  width: 80px;
  height: 36px;
}

.message-count .input {
  width: 240px;
  margin-left: 32px;
}

.message-count .el_font {
  /* margin-left: 32px; */
  margin-right: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.message-count .defult1 {
  color: #ffffff;
  background: linear-gradient(313deg, #2e59ec 0%, #1a91ff 100%);
}

.message-count .defult2 {
  margin-left: 16px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  color: #333333;
}

.zhuyao {
  margin-top: 24px;
}

.message-count .message-bottom {
  min-height: 200px;
  margin-top: 16px;
  width: 100%;
  padding-bottom: 20px;
  background: #ffffff;
  border-radius: 4px;
}

.styleMargin {
  margin-right: 5px;
}

.analyse-container {
  width: 100%;
  height: 450px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  text-align: center;
}

.analyse-container-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.analyse-container-img p {
  font-size: 14px;
  color: #999999;
}

.bottom-count {
  display: flex;
  padding: 24px 50px;
  border-bottom: 1px solid #e4e6ec;
}

.bottom-count:hover {
  box-shadow: 0px 2px 4px 4px rgb(0 0 0 / 6%);
}

.bottom-text-box {
  line-height: 25px;
  width: 1232px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom-title {
  cursor: pointer;
  max-width: 1008px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.bottom-count-text span {
  display: inline-block;
}

.bottom-count-text {
  display: inline-block;
  margin-left: 40px;
  width: 1244px;
}

.type {
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding: 0 8px;
  height: 28px;
  line-height: 28px;
  border-radius: 1px;
}

.website {
  height: 28px;
  min-width: 96px;
  background: #868bff;
  border-radius: 1px;
}

.publics {
  font-size: 14px;
  /* color: #888; */
  color: #000;
  margin-top: 16px;
}

.xia-span-img {
  transform: rotate(-90deg);
}

.bottom-text {
  cursor: pointer;
  overflow: hidden;
  /* height: 40px;
  line-height: 20px;
  font-size: 14px; */
  line-height: 30px;
  height: 66px;
  font-size: 18px;
  color: #444;
  margin-top: 16px;
}

.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.original {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #2e59ec;
  margin-left: 16px;
}

.original img {
  position: relative;
  top: 4px;
}

.caozuo-copy .el-button {
  width: 100%;
  padding: 9px 24px;
  font-size: 14px;
  font-weight: bold;
}

.type-count {
  margin-top: 19px;
}

.website {
  margin-top: 24px;
}

.caozuo-box {
  width: 1232px;
  display: flex;
  justify-content: space-between;
}

.publics-caozuo {
  display: flex;
  align-items: center;
}

.publics-caozuo>span {
  margin-left: 45px;
}

.publics-caozuo>span:nth-child(1) {
  margin-left: 0;
}

.caozuo-copy {
  margin-left: 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.chongzhi {
  color: #333333;
  width: 60px;
  font-size: 14px;
  height: 32px;
  box-sizing: border-box;
}

.zhankai-change {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.zhankai-change span {
  display: flex;
  align-items: center;
}

.xia-span {
  font-size: 14px;
  color: #2e59ec;
  width: 41px;
}

.animation {
  transition: all 0.7s;
}

.defultHeight {
  height: 0px;
  overflow: hidden;
}

.shouHeight {
  overflow: hidden;
}

.bottom-key {
  margin-left: 0px;
}

.message-count .block .total {
  left: 7%;
}

.refresh {
  font-size: 14px;
  color: #333333;
  box-sizing: border-box;
  height: 32px;
}

.quick_copy {
  color: #2e59ec;
  cursor: pointer;
  font-size: 14px;
}

.message_newData {
  color: #2e59ec;
  width: 190px;
  text-align: center;
  font-size: 12px;
  line-height: 26px;
  background-color: #fff;
  border-radius: 13px;
  height: 26px;
  margin: 16px auto 0;
}

.message_newData span {
  text-decoration: underline;
  cursor: pointer;
}

.fade-enter-active {
  transition: height 0.25s;
}

.fade-enter {
  height: 0;
}

#zb_message_setting {
  color: #0079fe;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  left: 140px;
  top: 115px;
}

#zb_message_setting_box {
  position: absolute;
  left: 30px;
  top: 0;
  font-size: 13px;
  line-height: 25px;
  font-weight: normal;
  z-index: 20;
  border-radius: 3px;
  border: 1px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  user-select: none;
  padding: 0 15px;
  background-color: white;
  white-space: nowrap;
}

.zb_message_setting_hang {
  color: #333;
  width: 100%;
  padding: 7px 0;
}

.zb_message_setting_hang:hover {
  color: #0079fe;
}

.zb_message_dialog {
  position: relative;
}

.zb_message_dialog>div {
  margin-bottom: 20px;
}

.zb_message_dialog_box {
  background-color: #f5f7fb;
  padding: 30px 20px;
  border-radius: 5px;
}

.zb_message_dialog_box>* {
  margin-top: 20px;
}

.zb_message_dialog_title {
  color: #333;
  font-size: 15px;
  font-weight: bold;
  padding: 0 10px;
}

.zb_message_dialog_title span {
  font-size: 14px;
  font-weight: normal;
  color: #666;
}

.zb_message_dialog_option {
  position: relative;
  font-weight: bold;
}

.zb_message_dialog_option .zb_message_dialog_span {
  position: absolute;
  top: 0;
  left: 150px;
  color: #999;
}

#zb_message_dialog_checkbox ::v-deep label {
  margin-right: 30px;
}

.zb_message_dialog_box2>* {
  margin-top: 20px;
}

.zb_message_dialog_box2 ::v-deep .el-input__inner {
  padding: 0 20px !important;
}

.zb_message_dialog_box2 ::v-deep .el-input-number {
  width: 100px;
}

.zb_message_dialog_box2 ::v-deep span[role="button"] {
  background-color: #f5f7fa;
}

.zb_message_dialog_strong {
  height: 150px;
  transition: height 0.3s;
  overflow: hidden;
}

.zb_message_dialog_strong>div {
  margin-left: 30px;
  margin-top: 20px;
}

.tra-enter {
  height: 0px;
}

.tra-leave-to {
  height: 0px;
}

.zb_message_dialog_tag {
  display: inline-block;
  width: 87%;
}

.zb_message_dialog_tag ::v-deep .el-tag--light {
  max-width: 100%;
  height: auto;
  white-space: normal;
}

#zb_message_change>div {
  display: inline-block;
  padding: 6px 10px 8px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 6px;
  color: #555;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
}

#zb_message_change .zb_message_change_select {
  color: #0079fe;
}

.comment-box-yujing {
  width: 60px;
  margin-right: 16px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: bold;
  background: #ffedef;
  color: #ea3342;
}

.zb_message_dialog_tip {
  position: absolute;
  top: 8px;
  right: -20px;
  color: #666;
}

.zb_sentiment_copy {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
}

.serch-row {
  width: 100%;
  min-height: 58px;
  display: flex;
  align-items: center;
}

.serch-row-title {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  margin-right: 40px;
}

.plan-tab {
  align-items: center;
  width: 77%;
  display: inline-block;
}

.plan-tab-li {
  padding: 5px 16px;
  background: #f6f7fa;
  border-radius: 4px;
  color: #333333;
  display: inline-block;
  height: 32px;
  line-height: 22px;
  cursor: pointer;
  margin-right: 16px;
}

.plan-tab-li-active {
  background: #f2f8ff;
  border-radius: 4px;
  border: 1px solid #89a4ff;
}

.plan-tab-li span {
  font-size: 12px;
  color: red;
}

.serch-save-prompt {
  width: 100%;
  padding: 10px;
  height: 78px;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  margin-top: 24px;
}
</style>
