<template>
    <div class="input_box">
        <div class="el_font">标题</div>
        <el-input clearable class="input" size="medium" placeholder="请输入标题" v-model="formData.title">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="el_font m-r">正文</div>
        <el-input clearable class="input" size="medium" placeholder="请输入正文" v-model="formData.content">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="el_font m-r">作者</div>
        <el-input clearable class="input" size="medium" placeholder="请输入作者" v-model="formData.author">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="el_font2 m-r" v-if="listType == 'sentiment'">作者ID</div>
        <el-input clearable v-if="listType == 'sentiment'" class="input" size="medium" placeholder="请输入作者"
            v-model="formData.interiorsMediaLevel">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="el_font m-r">来源</div>
        <el-input clearable class="input" size="medium" placeholder="请输入来源" v-model="formData.source">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <!-- <div class="el_font m-r">排除词</div>
      <el-input clearable class="input" size="medium" placeholder="请输入排除词" v-model="formData.source">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input> -->
    </div>
</template>

<script>
export default {
    props: ['listType'],
    data() {
        return {
            formData: {
                title: '',
                content: '',
                author: '',
                source: '',
                interiorsMediaLevel: ''
            }
        }
    },
    methods: {
        getFromData() {
            let Data = {}
            Object.keys(this.formData).forEach(name => {
                if (this.formData[name]) Data[name] = this.formData[name]
            })
            return Data || {}
        },
        setFromData(obj) {
            const { title, content, author, source, interiorsMediaLevel } = obj;
            this.formData = { title, content, author, source, interiorsMediaLevel }
        },
        clearFromData() {
            this.formData = this.$options.data().formData
            // this.formData  = {
            //     title:'',
            //     content:'',
            //     author:'',
            //     source:''
            // }
        }
    }
}
</script>

<style scoped>
.el_font {
    /* margin-left: 32px; */
    /* margin-right: 12px; */
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    min-width: 40px;
    display: flex;
    align-items: center;
}

.el_font2 {
    /* margin-left: 32px; */
    /* margin-right: 12px; */
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    min-width: 65px;
    display: flex;
    align-items: center;
}

.input_box {
    display: flex;
    margin-top: 3px;
    /* display: flex; */
    /* align-items: center; */

}

.m-r {
    margin-left: 12px;
}
</style>